.app-modal {
  @include mixOverLayerCentered(980);

  background-color: $color-transparent-primary;
}

.modal {
  @include mixFlexAlignJustify(center, space-between, column);

  //gap: 2.5rem;
  padding: 3.5rem 2.5rem 2rem 2.5rem;
  margin: auto 1rem;
  min-width: 25rem;
  box-shadow: $box-shadow-primary;
  background: $color-background-default;
  border-radius: 1rem;
  position: relative;
  text-align: center;

  .modal-description {
    @include mixFlexAlignJustify(center, center, column);
    gap: 0.5rem;
    color: $color-text-hint;
    font-size: 0.75rem;
    margin-top: 0.25rem;

    h6 {
      font-size: 0.875rem;
    }
  }

  //.modal-content {
  //  @include mixFlexAlignJustify(center, space-evenly, column);
  //  gap: 0;
  //  width: 100%;
  //  flex: 1;
  //
  //  .total-balance {
  //    font-family: $font-primary-semibold;
  //  }
  //}

  .space-element {
    flex: 1;
  }

  .modal-header {
    @include mixFlexAlignJustify(center, space-between);
    width: 100%;
    position: absolute;
    top: 0;
    background-position: center;
    padding: 1.75rem;

    button {
      height: 0.875rem;
      padding: 0;
    }

    img {
      object-fit: cover;
    }
  }

  h3 {
    color: $color-text-secondary;
    line-height: 2.3rem;
    margin-bottom: 1.5rem;
  }

  .action-buttons {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;

    button {
      font-size: 0.875rem;
      padding: 0 1.5rem;
    }
  }

  .hint-message {
    font-size: 0.75rem;
    font-family: $font-primary-regular;
    color: $color-text-hint;
    line-height: 1.25rem;
  }

  .success {
    color: #09BC06
  }

  .fail {
    color: #E30000;
  }

  .status {
    margin-top: 2rem;
    line-height: 1.75rem;
  }

  .value {
    @include mixFlexAlignJustify(baseline, center);

    gap: 0.25rem;

    span {
      font-size: 1.5rem;
      font-family: $font-primary-medium;
    }
  }

  //.label {
  //  font-size: 1.25rem;
  //  margin-top: 0.5rem;
  //
  //}

  .description {
    margin-top: 1.5rem;
    font-size: 0.75rem;
    max-width: 18rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
    color: $color-text-hint;
  }

  // Deposit modal ==================================================================================================

  .deposit-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    .modal-input {
      position: relative;
      width: 60%;

      input {
        width: 100%;
        padding-right: 4rem;
        padding-left: 1.75rem;
        padding-top: 0.25rem;
      }

      // hide number input arrows
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .currency-symbol {
        height: 3rem;
        left: 1rem;
        position: absolute;
        line-height: 3.25rem;
        color: $color-text-default;
        font-family: $font-primary-medium;
        font-size: 1.25rem;
      }

      .currency {
        height: 3rem;
        right: 1rem;
        position: absolute;
        line-height: 3.25rem;
      }

      //.error-message {
      //  margin-top: 0.5rem;
      //  font-size: 0.75rem;
      //  text-align: left;
      //  color: $color-error-primary;
      //}
    }

    .withdraw-checkbox {
      margin-top: 1rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &.disabled {
        color: $color-text-disabled;
      }

      input {
        margin: 0;
        accent-color: $color-background-primary;
      }

      h6 {
        margin-top: 0.2rem;
      }
    }

    .balance {
      @include mixFlexAlignJustify(center, space-between, column);

      gap: 0.5rem;
      font-size: 0.875rem;
      max-width: 80%;
      text-align: center;
      line-height: 1.5rem;

      .value {
        span {
          font-size: 1.25rem;
          font-family: $font-primary-medium;
        }
      }
    }

    .input-label {
      margin: 2.5rem 0 1.5rem 0
    }

    .edit-permission {
      color: $color-text-tertiary;
      font-family: $font-primary-regular;
      font-size: 0.875rem;
      text-decoration: underline;
    }

    .permission-value {
      margin: 2.5rem 0 0.25rem 0;
      text-align: center;

      .label {
        font-size: 1.25rem;
      }

      .value {
        margin-top: 0.75rem;

        span {
          font-size: 1.5rem;
          font-family: $font-primary-medium;
          margin-right: 0.25rem;
        }
      }
    }
  }
}


// Metamask modal ==========================================================================================================

.metamask {
  display: flex;
  gap: 3rem;
  padding-bottom: 2.5rem;

  .metamask-content {
    @include mixFlexAlignJustify(center, center);

    padding: 1.5rem 2rem;
    margin-top: 1rem;
    border: mixItemBorder($color-border-input);
    border-radius: 0.5rem;

    .title {
      padding: 0 1rem;
      color: $color-text-default;
    }
  }

  .action-buttons {
    margin: 0;
  }
}

//Inform modal ========================================================================================
.inform-modal {
  gap: 1.5rem;

  .inform-content {
    @include mixFlexAlignJustify(flex-start, center, column);
    text-align: left;
    gap: 0.5rem;

    :not(.title) {
      font-size: 0.875rem;
    }

    .title {
      font-family: $font-primary-medium;
      //font-size: 0.75rem;
    }
  }
}

//Menu modal ==========================================================================================
.modal-menu {
  height: 100%;
  width: 100%;
  margin: 0;
  justify-content: center;
  border-radius: 0;

  background: linear-gradient(338.62deg, #3B0C57 22.62%, #3C38F2 89.13%);
  box-shadow: 0 4px 24px -1px rgba(37, 22, 81, 0.16);
  backdrop-filter: blur(20px);
}

.menu-links-block {
  @include mixFlexAlignJustify(center, center, column);
  gap: 4rem;

  a {
    font-family: $font-primary-medium;
    color: $color-text-primary;
  }

  button {
    border: 0;
    background-color: $color-background-default;

    &:hover {
      background-color: $color-background-default;
      color: $color-text-secondary;
    }
  }
}

// global ==============================================================================================

.modal-large {
  width: 760px;
  min-height: 500px;
}

.modal-medium {
  width: 40rem;
}

.transaction-result-modal {
  .status {
    margin-top: 1.5rem;
    line-height: 1.75rem;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    .value {
      font-family: $font-primary-medium;
      color: $color-grey-strong;
      letter-spacing: -0.5px;
      font-size: 27px;
      align-items: center;

      .symbol {
        font-size: 18px;
      }
    }

    .label {
      font-size: 24px;
      font-family: $font-primary-medium;
    }
  }

  .action-buttons {
    .button-outlined-primary {
      color: $color-grey-strong;
      border: 1px solid $color-grey-strong;
      background-color: #fff;
    }
  }

  .description {
    padding-top: 55px;
    padding-bottom: 33px;
    margin: 0;
  }
}

.modal-small {
  width: 27rem;
}

.input {
  width: 100%;
  border-radius: 0.75rem;
  border: mixItemBorder($color-border-input);
  padding: 0.75rem 1rem;
  color: $color-text-default;
  font-family: $font-primary-medium;
  font-size: 1rem;
  line-height: 1.75rem;


  &:focus {
    border: mixItemBorder($color-border-input-focus);
  }

  &.error {
    border-color: $color-border-input-error;
  }
}

.modal-input {
  width: 100%;
  position: relative;

  .input-label {
    margin-bottom: 1rem;
  }

  .error-message {
    position: absolute;
    margin-top: -1.5rem;
    font-size: 0.75rem;
    text-align: left;
    color: $color-error-primary;
  }
}

.modal-input-box {
  @include mixFlexAlignJustify(center, center);
  gap: 1rem;
  width: 100%;
}

// contents =======================================================================
.withdraw-main {
  gap: 3.75rem;
}

.withdraw-yield {
  @include mixFlexAlignJustify(center, space-evenly, column);
  gap: 0;
  width: 100%;
  flex: 1;
  max-width: 32rem;

  .hint-message {
    margin-top: 0.5rem;
    text-align: start;
  }

  .yield-input-container {
    @include mixFlexAlignJustify(center, center, column);
    gap: 1rem;
    width: 100%;
  }

  .withdraw-investment {
    color: #0C4AE7;
    font-family: $font-primary-medium;
    font-size: 0.75rem;
    line-height: 1.25rem;

    p {
      text-decoration: underline;
    }
  }

  //.yield-input-container {
  //  width: 100%;
  //  height: 3.25rem;
  //  padding: 0.375rem;
  //  border: 1px #C8C8C8 solid;
  //  border-radius: 0.75rem;
  //}

  .yield-input-value {
    @include mixFlexAlignJustify(center, center);
    gap: 0.5rem;
    height: 100%;
    width: fit-content;
    background: #F1F1F1;
    padding: 0.375rem 0.75rem;
    border-radius: 0.5rem;
    color: #999494;
  }
}

.reinvest-yield {
  @include mixFlexAlignJustify(center, space-between, column);
  width: 100%;
  gap: 0;
  flex: 1;

  .reinvest-sub-title {
    @include mixFlexAlignJustify(center, center);
    gap: 0.375rem;
    margin-top: 0.25rem;

    h6 {
      font-size: 0.875rem;
    }
  }
}

// Transaction Modal =======================================================================

.transaction-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  margin: auto 1rem;
  min-width: 25rem;
  box-shadow: 2px 2px 24px 1px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 1rem;
  position: relative;
  text-align: center;
  max-width: 760px;
  min-height: 500px;

  .transaction-modal__header {
    width: 100%;
    display: flex;
    min-height: 30px;

    .transaction-modal__header__btn {
      display: flex;
      align-items: center;
    }

    .transaction-modal__header__left{
      flex: 1;
      display: flex;
      justify-content: start;
    }
    .transaction-modal__header__right{
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .transaction-modal__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;

    .transaction-modal__header__title-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font: $font-primary-semibold;

      .transaction-modal__title {
        font-size: 1.5rem;
        line-height: 3rem;
        color: $color-text-secondary;
      }
      .transaction-modal__subtitle {
        font-size: 0.875rem; // 14px
        color: #1B0F30;
      }
    }
    .transaction-modal__description {
      font-family: $font-primary-regular;
      font-size: 0.75rem; //12px
      line-height: 1.25rem; //20px
      color: $color-grey-strong;
      letter-spacing: 0.5px;
      max-width: 600px;
    }
    .transaction-modal__system-message {
      font-family: $font-primary-regular;
      font-size: 0.68rem; //11px
      line-height: 1.25rem; //20px
      color: $color-grey-strong;
      letter-spacing: 0.5px;
      text-align: start;
      padding: 0 1rem 0.5rem 1rem;
    }
    .transaction-modal__bonus-action{
      font-family: $font-primary-regular;
      font-size: 0.68rem; //11px
      line-height: 1.25rem; //20px
      color: #0C4AE7;
      letter-spacing: 0.5px;
      text-align: start;
      padding: 0 1rem;
      margin-top: 1rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .transaction-modal__content {
    display: flex;
    flex: 1 1;
    align-items: center;
    width: 100%;
    justify-content: center;

    .transaction-container {
      width:83%;

      .locked-transactions-container {
        padding: 1rem;
        margin-top: 8px;
        border: 1px solid $color-grey-light;
        border-radius: 18px;

        .locked-transactions-container__content {
          display: flex;
          flex-direction: column;
          max-height: 132px;
          scroll-behavior: smooth;
          overflow: auto;
          gap: 12px;
          overscroll-behavior: contain;

          .locked-transaction-row {
            display: flex;
            justify-content: start;
            align-items: center;
            font-family: $font-primary-regular;
            font-size: 0.75rem; //12px
            color: $color-grey-strong;
            letter-spacing: 0.5px;
            text-align: start;
            padding-right: 5px;

            .locked-transaction__value {
              font-family: $font-primary-medium;
              font-size: 0.75rem; //12px
              line-height: 1.25rem; //20px
              color: $color-grey-strong;
              letter-spacing: 0.5px;
              text-align: start;
              margin-right: 20px;
            }
            .locked-transaction__locked-until {
              font-family: $font-primary-regular;
              font-size: 11px;
              padding: 2px 0;
              color: $color-grey-strong;
              letter-spacing: 0.5px;
              text-align: start;
              flex: 1;
            }
          }
        }
      }
    }


    .confirmation-message {
      font-family: $font-primary-medium;
      font-size: 16px;
      color: $color-black-strong
    }

  }

  .action-buttons {
    justify-content: center;
  }


  .reinvest-yield__custom-error {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    background: #FFEFEF;
    border-radius: 8px;
    margin-top: 32px;

    p{
      font-family: $font-primary-regular;
      font-size: 0.7rem;
      color: #7E6161;
      letter-spacing: 0.1px;
      text-align: left;
    }
    img {
      margin-right: 8px;
      width: 22px;
      height: 22px;
    }
  }
}

  .contract {
    @include mixFlexAlignJustify(center, center, column);
    gap: 28px;
    position: relative;

    .pool-name {
      @include mixFlexAlignJustify(center, center);
      gap: 1rem;
      padding: 0.5rem 0.75rem;
      border-radius: 2.5rem;

      h6 {
        font-family: $font-primary-medium;
        height: 0.75rem;
      }

      h4 {
        font-size: 1.125rem;
      }

      img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .pool-actions {
      @include mixFlexAlignJustify(center, center);
    }

    a {
      width: 1.25rem;
      height: 1.25rem;
    }

    .address {
      display: flex;
      height: 2.25rem;
      line-height: 2.325rem;
      padding: 0 1rem;
      position: relative;
      border: mixItemBorder($color-border-input);
      border-radius: $border-radius-default;
      margin-right: 20px;
      font-size: 0.875rem;
      font-family: $font-primary-medium;
    }

    button {
      padding: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 10px;
    }
  }

  .copy-popup {
    position: absolute;
    padding: 0.25rem;
    border: mixItemBorder($color-border-input);
    background-color: $color-background-toned;
    border-radius: 0.5rem;
    top: 3.5rem;
    right: 1rem;
    font-family: $font-primary-light;
    font-size: 0.75rem;
  }

.pool-row-widget {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  box-shadow: 1px 1px 16px 0px #052E6B14;
  border: 1px solid #4F3EFF;
  background-color: #F7F7FF;
  border-radius: 8px;

  .pool-card-image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .pool-info{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    max-width: 80%;

    h3 {
      font-family: $font-primary-medium;
      font-size: 18px;
      color: $color-black-strong;
      flex: 1;
      min-width: max-content;
    }

    .pool-widget__details {
      display:flex;
      gap: 40px;

      .pool-detail-block {
        flex: 1;
        display: flex;
        flex-direction: column;

        .label{
          font-size: 11px;
          min-width: max-content;
          color: $color-black-strong;
          font-family: $font-primary-regular;
        }
        .value-content{
          display: flex;
          font-size: 16px;
          font-family: $font-primary-medium;
          gap: 0.4ch;
        }
      }
    }
  }

  .pool-details{
    display:flex;
  }

}
