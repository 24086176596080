// impact info with icons ===================================================

.impact-info-box {
  display: grid;
  width: 100%;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(4, minmax(50px, 163px));
  justify-content: space-between;
  row-gap: 1.25rem;
  column-gap: 1.25rem;

  @include mixBreakPointMD {
    grid-template-columns: repeat(2, minmax(50px, 163px));
    justify-content: center;
  }

  &.horizontal {
    row-gap: 3rem;
    column-gap: 6rem;

    @include mixBreakPointSM {
      column-gap: 3rem;
    }
  }

  &.vertical {
    grid-template-rows: repeat(2, minmax(50px, 144px));
    grid-template-columns: none;
    justify-content: center;
    row-gap: 0;

    background-color: $color-background-disabled;
    border-radius: 0.5rem;
    max-width: 10rem;
    height: fit-content;

    .impact-info-block {
      svg {
        height: 5rem;
        width: 5rem;
      }

      h3 {
        font-family: $font-primary-regular;
        font-size: 1rem;
        height: 1.375rem;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span {
        font-size: 0.75rem;
        color: $color-text-tertiary;
      }
    }
  }
}

.emission-energy-box {
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .info-block {
    flex: 1;

    h4 {
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    .description {
      line-height: 1.75rem;
      white-space: pre-line;
    }
  }
}

// account impact info ============================================================

.account-impact-info-box {
  @include mixFlexAlignJustify(center, space-between);
  width: 100%;

  //display: grid;
  //grid-auto-columns: max-content;
  //grid-template-columns: repeat(4, minmax(50px, 163px));
  //justify-content: space-between;
  //row-gap: 1.25rem;
  //column-gap: 1.25rem;

  .account-impact {
    @include mixFlexAlignJustify(center, center, column);
    gap: 0.5rem;

    .value {
      color: $color-text-secondary;
      font-family: $font-primary-thin;
      font-size: 7.5rem;
      line-height: 100%;

      @include mixBreakPointSM {
        font-size: 5rem;
      }
    }

    .title {
      font-size: 1.25rem;
      line-height: 100%;
    }
  }
}

//work flow =======================================================================

.work-flow-box {
  position: relative;
  max-width: 22rem;

  @include mixBreakPointSM {
    width: 80%;
    margin-left: 5.25rem;
  }

  .badge-block {
    @include mixFlexAlignJustify(center, space-around);

    .rotate180 {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .long-arrow {
    position: absolute;
    top: 308px;
    left: -40px;
  }
}

// stories ============================================================================

.story-cards-box {
  width: 100%;

  .card-container {

    display: grid;
    grid-template-areas: 'a0 a0 a0 a1'
                         'a0 a0 a0 a2'
                         'a0 a0 a0 a3';
    grid-template-rows: 15rem 15rem 15rem;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 1.25rem;
    width: 100%;

    @include mixBreakPointXL {
      grid-template-rows: 12rem 12rem 12rem;
    }

    @include mixBreakPointL {
      grid-template-rows: 10rem 10rem 10rem;
    }

    @include mixBreakPointLG {
      grid-template-rows: 12rem 12rem 12rem 12rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'a0 a0 a0'
                           'a0 a0 a0'
                           'a0 a0 a0'
                           'a1 a2 a3';
    }

    @include mixBreakPointSM {
      max-width: 25rem;
      grid-template-rows: 10rem 10rem 10rem 10rem;
      grid-template-columns: repeat( auto-fit, minmax(50px, 100%) );
      grid-template-areas: 'a0'
                           'a1'
                           'a2'
                           'a3';
    }

    .a0 {
      grid-area: a0;

      .story-info {
        @include mixFlexAlignJustify(start, start, column);
        gap: 0.5rem;

        bottom: 1.5rem;
        left: 1.5rem;

        @include mixBreakPointSM {
          bottom: 0.75rem;
          left: 1rem;
        }

        h4 {
          font-size: 1.875rem;
          line-height: 2.875rem;
          @include mixBreakPointSM {
            font-size: 1.25rem;
          }
        }

        span {
          font-size: 1rem;
          line-height: 1.75rem;

          @include mixBreakPointSM {
            font-size: 0.875rem;
          }
        }
      }
    }

    .a1 {
      grid-area: a1;
    }

    .a2 {
      grid-area: a2;
    }

    .a3 {
      grid-area: a3;
    }
  }
}

.cards-slide {
  @include mixFlexAlignJustify(center, center);
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 14.75rem; //236px
  width: 100%;
  padding: 0 0.875rem;

  @include mixBreakPointLG {
    justify-content: start
  }


  &::-webkit-scrollbar {
    display: none;
  }

  .card-container {
    display: flex;
    gap: 1rem;
    height: 100%;
  }

  .story-card {
    height: 100%;
    width: 375px;
    flex: 0 0 auto;
  }

  -webkit-overflow-scrolling: touch;
}

//companies ===============================================================================

.company-cards-box {
  display: grid;
  width: 100%;
  grid-auto-columns: max-content;
  grid-template-columns: repeat( 5, minmax(50px, 140px) );
  justify-content: center;
  column-gap: 5rem;

  @include mixBreakPointLG {
    column-gap: 3rem;
  }

  @include mixBreakPointMD {
    grid-template-columns: repeat(auto-fit, minmax(20px, 140px) );
    column-gap: 2.5rem;
  }

  @include mixBreakPointSM {
    grid-template-columns: repeat(auto-fit, minmax(20px, 140px) );
    column-gap: 1.5rem;
  }

  .image {
    height: 140px;
    width: 140px;
    object-fit: cover;

    img {
      height: 100%;
      width: 100%;
      filter: grayscale(1);

      &:hover {
        filter: none
      }
    }

    border-radius: 0.5rem;
    overflow: hidden;
  }
}

// testimonials ==========================================================================

.testimonial-cards-box {
  margin: auto;
  position: relative;
  width: 100%;
  //display: grid;
  //place-items: center;

  .slide-track {
    display: flex;
    //width: calc(26rem * 12);
    animation: scroll 20s linear infinite;
    gap: 1.25rem;
    padding: 4rem 0;

    @include mixBreakPointSM {
      width: 100%;
      gap: 1rem;
      animation: none;
      padding: 3.25rem 1.5rem;
      overflow-x: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

    }

    &:hover {
      animation-play-state: paused;
    }
  }

  .card {
    border-radius: 1rem;
    background: none;
  }
}

//members ====================================================================================

.member-cards-box {
  display: grid;
  width: 100%;
  grid-auto-columns: max-content;
  grid-template-columns: repeat( auto-fit, minmax(50px, 255px) );
  justify-content: center;
  row-gap: 4.5rem;
  column-gap: 1.25rem;
}

//reinvest pool cards=========================================================================

.reinvest-pool-cards-box {
  @include mixFlexAlignJustify(center, center, column);
  gap: 0.5rem;
  width: 100%;
}

//pools ======================================================================================

.pool-cards-box {
  @include mixFlexAlignJustify(stretch, center, column);

  gap: 1.25rem;
  width: 100%;
  max-width: 1080px;

  .no-pool {
    text-align: center;
    color: $color-text-hint;
    font-family: $font-primary-semibold;
    letter-spacing: 0.5px;
  }

/*  @include mixBreakPointLG {
    display: grid;
    width: 100%;
    grid-auto-columns: max-content;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 50%));
    justify-content: center;
  }*/
/*  @include mixBreakPointMD {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 100%));
  }*/
  @include mixBreakPointSM {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 100%));
  }
}

//pool info =============================================================================

.pool-info-box {
  width: 100%;
  box-shadow: 0 4px 24px -1px rgba(38, 9, 55, 0.16);
  background-image: linear-gradient(142.39deg, rgba(255, 255, 255, 0.65) 10.52%, rgba(255, 255, 255, 0.8) 98.31%);
  border-radius: 1rem;
  backdrop-filter: blur(12px);
  position: sticky;
  overflow: hidden;
  button {
    width: 100%;
  }

  @include mixBreakPointMD {
    justify-content: center;
    max-width: 340px;
  }

  .pool-main-info {
    @include mixFlexAlignJustify(center, space-around, column);
    width: 100%;
    height: 17rem;
    padding: 1.5rem;
    font-family: $font-primary-medium;
    gap: 1rem;

    .hide-details{
      font-size: $font-primary-light;
      text-decoration: underline;
      color: $color-text-secondary;
      cursor: pointer;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: .5px;
    }

    .balance {
      margin-bottom: 0.25rem
    }

    @include mixBreakPointMD {
      height: 16rem;
      min-width: 18rem;
    }

    h2, h4 {
      font-family: $font-primary-regular;
    }

    @include mixBreakPointMD {
      h4 {
        font-size: 1.125rem;
      }
    }

    @include mixBreakPointSM {
      h4 {
        font-size: 1rem;
      }
    }


    .balance {
      @include mixFlexAlignJustify(start, center, column);
      gap: 0.5rem;
      width: 100%;
      color: $color-text-secondary;
      padding: 0 0.5rem;

      .label {
        font-size: 0.875rem;
        font-family: $font-primary-regular;
      }

      .value {
        @include mixFlexAlignJustify(center, center);
        gap: 0.25rem;
        line-height: 1.5rem;

        h2 {
          font-size: 2.25rem;
        }

        .symbol {
          font-size: 1.375rem
        }
      }

      .info-tooltip {
        @include mixFlexAlignJustify(center, center);
        position: relative;
      }
    }

    .main-info {
      display: grid;
      grid-template-columns: auto 2px auto;
      align-items: start;
      padding: 0 0.5rem;
      width: 100%;
      color: $color-text-secondary;

      button {
        &.disabled {
          background-color: $color-background-disabled;
        }
      }

      .divider {
        height: 100%;
        width: 1px;
        background-color: $color-background-primary;
        border-radius: 1rem;
      }

      .item {
        @include mixFlexAlignJustify(start, center, column);
        text-align: center;
        gap: 0.5rem;
        max-width: fit-content;
        height: 100%;

        .label {
          font-size: 0.75rem;
          font-family: $font-primary-regular;
        }

        .value {
          line-height: 1rem;

          h4 {
            margin-right: 0.125rem;
            float: left;
          }

          .symbol {
            font-size: 0.875rem;
            float: left;
          }
        }
      }
    }

    .toggle-details {
      color: $color-text-secondary;
      text-align: center;
      text-decoration: underline;
      font-size: 0.875rem;
      line-height: 1.75rem;
      font-family: $font-primary-regular;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .pool-info {
    min-height: 50%;
    transition: all 2s linear;

    div {
      @include mixFlexAlignJustify(center, space-between);
      padding: 1rem 1.5rem;
      background-color: #E3E3E354;

      &:nth-child(odd) {
        background-color: #E3E3E3bd;
      }
    }
  }

  .reinvest {
    @include mixFlexAlignJustify(center, center, column);
    gap: 1.75rem;
    padding: 1.25rem;
  }

}

// pool name, overview ==================================================================

.pool-name-box {
  @include mixFlexAlignJustify(start, end, column);
  height: 256px;
  gap: 1rem;
  padding-bottom: 2rem;
  color: $color-text-primary;

  @include mixBreakPointMD {
    padding-bottom: 2.5rem;
    text-align: center;
    height: 210px;
    align-items: center;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3rem;

    @include mixBreakPointMD {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }

  .description {
    display: -webkit-box;
    line-height: 2rem;

    font-size: 1.25rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mixBreakPointMD {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
}

// pool media ===========================================================================

.media-box {
  @include mixFlexAlignJustify(center, flex-start);
  gap: 0.75rem;
  text-transform: uppercase;

  @include mixBreakPointMD {
    justify-content: center
  }

  h6 {
    font-size: 0.875rem;
  }
}

// article ==============================================================================

.article-box {
  @include mixFlexAlignJustify(flex-start, center, column);
  gap: 0.5rem;
  width: 100%;

  img {
    width: 100%;
    object-fit: contain;
  }


  @include mixBreakPointMD {
    text-align: center;
    align-items: center
  }

  .pool-description {
    line-height: 1.75rem;
    font-family: "Poppins", sans-serif;

    p {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    .skeleton {
      width: 100%;
      height: 5rem;
      border-radius: 0.5rem;
      background-color: $color-background-toned;
    }
  }

  h3 {
    width: 100%;
    line-height: 3rem;

    .skeleton {
      width: 50%;
      height: 2rem;
      border-radius: 0.5rem;
      background-color: $color-background-toned;
    }
  }
}

// transactions =========================================================================

.transaction-cards-box {
  @include mixFlexAlignJustify(flex-start, center, column);
  padding: 0.75rem 0.5rem;
  gap: 1rem;
  width: 100%;
  background: linear-gradient(0deg, #F6F7FB, #F6F7FB), linear-gradient(142.39deg, rgba(255, 255, 255, 0.8) 10.52%, rgba(255, 255, 255, 0.72) 98.31%);
  background-blend-mode: normal, overlay, normal;
  opacity: 0.92;
  backdrop-filter: blur(12px);
  border-radius: 1rem;

  h4 {
    line-height: 3rem;
    font-size: 1.125rem;
    font-family: $font-primary-medium;
    padding: 0 1rem;
    letter-spacing: 0.05rem;
  }

  .transactions {
    @include mixFlexAlignJustify(flex-start, center, column);
    gap: 0.25rem;
    width: 100%;
    overflow-y: auto;
  }
}

//projects ==============================================================================

.project-cards-box {
  display: grid;
  width: 100%;
  grid-auto-columns: max-content;
  grid-template-columns: repeat( auto-fit, minmax(50px, 360px) );
  gap: 1.25rem;

  @include mixBreakPointMD {
    justify-content: center
  }
}

//charts ==============================================================================

.chart-box {
  @include mixFlexAlignJustify(center, center);
  gap: 3rem;
  max-width: 75rem;
  width: 100%;

  @include mixBreakPointMD {
    flex-direction: column;
  }

  .chart-card {
    @include mixFlexAlignJustify(start, center, column);
    gap: 2rem;
    flex: 1;
    max-width: 530px;

    h3 {
      line-height: 3rem;
    }

    .chartjs-tooltip-shadow {
      box-shadow: 1px 1px 12px 0 rgba(57, 19, 105, 0.24);
    }

    .chart-info {
      @include mixFlexAlignJustify(center, center);
      gap: 3rem;

      .chart-info-item {
        @include mixFlexAlignJustify(start, center, column);

        .label {
          font-size: 0.75rem;
        }

        .value {
          font-size: 1.5rem;
          font-family: $font-primary-medium;
        }
      }
    }

    .chart-container{
      //@include mixFlexAlignJustify(start, center, column);
      width: 100%;
    }
  }
}

//investments ===========================================================================

.investment-section {
  @include mixFlexAlignJustify(center, center, column);
  width: 100%;
  max-width: 70rem;
  gap: 2rem;
}

.investment-cards-box {
  display: grid;
  width: 100%;
  grid-auto-columns: max-content;
  grid-template-columns: repeat( auto-fit, minmax(50px, 348px) );
  justify-content: center;
  gap: 1.25rem;
  margin: 0 0.5rem;
}

// cards slides =============================================================================

.card-slide-box {
  @include mixFlexAlignJustify(stretch, center, column);

  gap: 1.25rem;
  width: 100%;
}

.card-slide {
  display: flex;
  gap: 1.25rem;
  overflow: hidden;
  width: 100%;

  a {
    flex: 1;
    max-width: 255px;
  }

  max-height: 10rem;
}

// radio =====================================================================================
.radio-box {
  @include mixFlexAlignJustify(center, space-between);

  font-size: 0.875rem;
  width: 100%;
  padding: 0 0.5rem;

  $color1: #f4f4f4;
  $color2: $color-text-secondary;

  .value {
    font-size: 1rem;
  }

  .radio {
    input[type="radio"] {
      position: absolute;
      opacity: 0;

      + .radio-label {
        @include mixFlexAlignJustify(center, center);

        span {
          margin-top: 0.125rem;
        }

        &:before {
          content: '';
          border-radius: 100%;
          border: 2px solid $color2;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          position: relative;
          margin-right: 0.875em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        + .radio-label {
          &:before {
            background-color: $color2;
            box-shadow: inset 0 0 0 3px $color1;
            border: 2px solid $color2;

          }
        }
      }

      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $color2;
          }
        }
      }

      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 3px $color1;
            border-color: darken($color1, 25%);
            background: darken($color1, 25%);
          }
        }
      }

      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
}

hr {
  margin: 0;
  border: none;
  border-bottom: 1px #c8c8c8 solid;
  width: 100%
}
