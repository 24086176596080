.text-image-park {
  position: relative;
  @include mixFlexAlignJustify(center, center, column);
  height: 40rem;
  width: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .text-block {
    @include mixFlexAlignJustify(center, center, column);

    gap: 1.125rem;
    margin-top: 6rem;
    width: 22rem;
    height: 16rem;
    color: $color-text-primary;

    h2 {
      text-transform: uppercase;
      text-align: center;
    }

    p {
      text-align: center;
      line-height: 2.25rem;
    }
  }
}

.app-loader {
  @include mixOverLayerCentered(990);

  background-color: rgba(20, 11, 126, 0.6);
  color: $color-text-primary;

  .loader-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h4{
      white-space: normal;
      padding: 0 20%;
      margin-bottom: 1rem;
      color: $color-text-primary;
    }
  }

  .icon-loader-primary {
    stroke: $color-icon-loader-primary;
  }
}

.balance-tooltip {
  @include mixFlexAlignJustify(flex-start, space-between, column);
  position: absolute;
  box-shadow: 0 4px 24px -1px rgb(38 9 55 / 30%);
  background-color: #fff;
  opacity: 1;
  color: #2f2f2f;
  gap: 0.5rem;
  padding: 1rem;
  top: 1.875rem;
  border-radius: 0.5rem;
}

.tooltip-item {
  @include mixFlexAlignJustify(center, space-between);
  width: 100%;
  gap: 1rem;

  .tooltip-item-label {
    font-size: 1rem;
  }

  .tooltip-item-value {
    h4, .tooltip-item-symbol{
      font-size: 1rem;
    }
    @include mixFlexAlignJustify(center, center);
    gap: 0.25rem;
  }

}

