.app-footer {
  @include mixFlexAlignJustify(center, space-between, column);
  gap: 4.5rem;
  width: 100%;

  .links-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2.25rem;
    font-size: 0.875rem;

    a, span{
      font-family: $font-primary-medium;
      color: $color-text-default;
      cursor: pointer;

      .title {
        color: $color-text-secondary;
      }
    }
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    font-family: $font-primary-regular;
    font-size: 0.75rem; //12px
    margin-bottom: 6.5rem;
    margin-top: 20rem;

    .logo {
      height: 2.5rem;
      width: 2.5rem;
      background-image: url("../../images/Logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
