@tailwind components;
@tailwind utilities;


/* Hide scrollbar arrows for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-thumb {
  background: $color-grey-light;  /* Color of the scrollbar thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-grey-strong;  /* Color of the scrollbar thumb on hover */
}

html, body {
  margin: 0;
  font-size: $font-size-default;
  font-family: $font-primary-regular; // weight: 705
  color: $color-text-default;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

div, span, p, input, select, a {
  box-sizing: border-box;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  ////white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  box-sizing: border-box;
  font-family: $font-primary-semibold;

  &.title{
    color: $color-text-secondary;
  }
}

p {
  margin: 0;
}

h1 {
  font-size: 4rem; // 64px
}

h2 {
  font-size: 2rem; // 32px
}

h3 {
  font-size: 1.5rem; // 24px
}

h4 {
  font-size: 1.25rem; // 20px
}

h5 {
  font-size: 1rem; // 16px
}

h6 {
  font-size: 0.75rem; // 12px
}


a, img, a:link, a:visited, a:hover, a:focus, button, input, svg, svg:focus, input:focus, select, select:focus, span, div:focus, div:visited, div:active,{
  border: none;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  filter: none;
  @include mixPrimaryAnimations([color, background-color, opacity, transform]);
}

button, a {
  cursor: pointer;
  background-color: transparent;
  border-radius: $button-border-radius-primary;
}

button {
  font-size: 0.875rem;
  letter-spacing: 0.4px;
  font-family: $font-primary-medium;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  color: $color-text-disabled;
}

.small {
  height: $height-small;
  min-width: $width-small;
}

.medium {
  height: $height-medium;
  min-width: $width-medium;
}

.large {
  height: $height-large;
  min-width: $width-large;
}

svg {
  vertical-align: middle;
}

.over-layer-primary {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-transparent-overlay-secondary;
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}

.gradient {
  min-width: 100vw;
  position: absolute;
  z-index: -1;
}

.skeleton {
  background: $color-background-skeleton;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.card {
  background: rgb(255, 255, 255, 0.2);
  border-radius: 1rem;
}

.locked-value__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #F1F1F1;
  padding: 6px 8px;
  border-radius: 6px;
  max-width: 113px;

  .locked-value__icon {
    margin-right: 4px;
    svg {
      height: 13px;
      width: 13px;
    }
  }
  .locked-value__value {
    font-size: 10px;
    font-family: $font-primary-medium;
    color: $color-grey-strong;

    span {
      vertical-align: sub;
    }
  }
}

/*Locked value in pseudo-input on the withdraw yield modal screen*/
.pseudo-input {
  padding: 8px;
  .locked-value__container{
    min-width: max-content;
    .pseudo-input__icon {
      svg {
        height: 16px;
        width: 16px;
      }
    }
    .pseudo-input__value {
      font-size: 14px;
    }
  }
}

.strong-grey-text{
  color: $color-grey-strong;
  font-family: $font-primary-light;
  font-size: 14px;

}
.strong-grey-value{
  color: $color-grey-strong;
  font-family: $font-primary-semibold;
  font-size: 14px;
}
