.helios-app-page {

  .filled-investments {
    width: 100%;
    min-height: 100vh;
    background: #F7F7F8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .investments {
    @include mixFlexAlignJustify(center, center, column);
    gap: 40px;

    text-align: center;
    width: 100%;
    z-index: 25;
    margin-top: 5.5rem;
    max-width: 75rem;
    padding: 2rem;


    .empty-investments {
      @include mixFlexAlignJustify(center, center, column);
      color: #212121;
      font-size: 1.5rem;
      height: 200px;
      width: 100%;
      box-sizing: border-box;
    }

    .title-description{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
  }

  .account-impact-info {
    margin: 17rem 1rem 7rem;

    .gradient {
      min-width: 100vw;
      height: 2000px;
      top: 700px;
      position: absolute;
      left: auto;
      z-index: -1;
    }
  }
}

.featured-border-gradient {
  //background: linear-gradient(0deg, #FFFFFF, #FFFFFF) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box;
  //border-top-right-radius: 1rem;
  //border-bottom-right-radius: 1rem;
  //border-right: 1px solid transparent;
  //border-top: 1px solid transparent;
  //border-bottom: 1px solid transparent;
}

.featured-card {
  box-shadow: 0px 4px 24px -1px #6B08A73D;
  a {
    .pool-card {
      .pool-info {
        height: auto;
        background: linear-gradient(112.26deg, rgba(236, 23, 215, 0.02) 8.73%, rgba(52, 79, 218, 0.01) 84.89%),
        linear-gradient(112.26deg, rgba(236, 23, 215, 0.03) 8.73%, rgb(52 79 218 / 6%) 84.89%),
        linear-gradient(0deg, #eefbff, rgb(238, 250, 254)) padding-box,
        linear-gradient(112.26deg, rgba(236, 23, 215, 0.8509803922) 8.73%, #344FDA 84.89%) border-box;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-right: 1px solid transparent;
      }
    }
  }

  @include mixBreakPointSM {
    .pool-card {
      .pool-info {
        height: auto;
        background: linear-gradient(112.26deg, rgba(236, 23, 215, 0.02) 8.73%, rgba(52, 79, 218, 0.01) 84.89%),
        linear-gradient(112.26deg, rgba(236, 23, 215, 0.03) 8.73%, rgb(52 79 218 / 6%) 84.89%),
        linear-gradient(0deg, #eefbff, rgb(238, 250, 254)) padding-box,
        linear-gradient(112.26deg, rgba(236, 23, 215, 0.8509803922) 8.73%, #344FDA 84.89%) border-box;
        border-bottom: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0!important;
      }
    }
  }
}
