.page-layout {
  overflow: hidden;

  .footer {
    background-position-y: 2rem;
  }
}

.page-layout, .app-layout {
  @include mixFlexAlignJustify(center, space-between, column);
  position: relative;
  min-height: 100vh;

  .title-description {
    @include mixFlexAlignJustify(center, center, column);
    gap: 1.5rem;
    text-align: center;
    width: 100%;

    @include mixBreakPointSM {
      gap: 1rem;
    }

    h2 {
      line-height: 3rem;
      color: $color-text-secondary;
      letter-spacing: -0.01em;

      @include mixBreakPointSM {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
    }

    .description {
      font-size: 1.125rem;
      line-height: 2rem;
      letter-spacing: 0.1px;

    }
  }

  .discord {
    border-radius: 1rem;
    max-width: 100%;
    width: 100%;
  }


}

.app-layout {
  position: relative;
  //overflow: hidden;

  //.header {
  //  position: absolute;
  //  z-index: 30;
  //}

  .header {
    position: absolute;
    z-index: 30;
  }

  .discord {
    margin-top: 4rem;
    padding: 2rem;
    max-width: 71rem;
    width: 100%;
  }

  .gradient {
    min-width: 100vw;
    min-height: 40rem;
    position: absolute;
    top: -18rem;
    width: 100%;
    z-index: 1;
  }
}

.footer-gradient {
  position: relative;
  background-image: url("../../images/gradients/Footer.png");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  @include mixBreakPointLG {
    background-size: auto;
  }

}
